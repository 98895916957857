import React, { PropsWithChildren } from 'react';
import {
  Settings as SidebarSettings,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';
import GroupIcon from '@material-ui/icons/People';
import {
  AddIcon as CreateComponentIcon,
  SearchIcon,
  SettingsIcon as UserSettingsSignInAvatar,
  CatalogIcon as HomeIcon,
} from '../../theme/ibm-icons';

const SidebarLogo = () => {
  const { isOpen } = useSidebarOpenState();

  return (
    <>
      {isOpen ? (
        <div style={{ height: '42px', width: '100%', display: 'flex', justifyContent: 'center', marginTop: '1.5rem', marginBottom: '2rem', marginLeft: '-15px' }}>
          <img src="/logo_with_text.png" alt="Logo" style={{ height: '100%', objectFit: 'contain' }} />
        </div>
      ) : (
        <div style={{ height: '42px', width: '100%', display: 'flex', justifyContent: 'center', marginTop: '1.5rem', marginBottom: '2rem', marginLeft: 0 }}>
          <img src="/logo_without_text.png" alt="Logo" style={{ height: '100%', objectFit: 'contain' }} />
        </div>
      )}
    </>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="catalog" text="Catalog" />
        <MyGroupsSidebarItem
          singularTitle="My Group"
          pluralTitle="My Groups"
          icon={GroupIcon}
        />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          {/* Items in this group will be scrollable if they run out of space */}
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
