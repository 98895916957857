import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  LinkValidator,
  validateLink,
} from './LinkValidator/LinkValidator';
import {
  SignedInUser,
  validateSignedInUser,
} from './SignedInUser/SignedInUser';
import { EntityNameDefaultValidator, validateEntityNameDefaultNamespace } from './EntityNameDefaultValidator/EntityNameDefaultValidator';

export const ValidateLinkExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'LinkValidator',
    component: LinkValidator,
    validation: validateLink,
  }),
);

export const SignedInUserExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'SignedInUser',
    component: SignedInUser,
    validation: validateSignedInUser,
  })
)

export const EntityNameDefaultValidatorExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'EntityNameDefaultValidator',
    component: EntityNameDefaultValidator,
    validation: validateEntityNameDefaultNamespace,
  })
)