import React from 'react';

import { Add, Api, Book, Catalog, CatalogPublish, ContainerSoftware, Home, Gift, Radar, Search, Settings, LightFilled } from '@carbon/icons-react';

export const HomeIcon = () => (
  <Home size="24" />
);

export const ApiIcon = () => (
  <Api size="24" />
);

export const BookIcon = () => (
  <Book size="24" />
);

export const AddIcon = () => (
  <Add size="32" />
);

export const SearchIcon = () => (
  <Search size="32" />
);

export const RadarIcon = () => (
  <Radar size="24" />
);

export const SettingsIcon = () => (
  <Settings size="24" />
);

export const ContainerSoftwareIcon = () => (
  <ContainerSoftware size="24" />
);

export const GiftIcon = () => (
  <Gift size="24" />
);

export const CatalogIcon = () => (
  <Catalog size="24" />
);

export const CatalogAddIcon = () => (
  <CatalogPublish size="24" />
);

export const LightFilledIcon = () => (
  <LightFilled size="24" />
);