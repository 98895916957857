import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useEntityRollingMetrics } from '../../hooks/useEntityRollingMetrics';
import { Metric } from '@itz/backstage-plugin-telemetry-backend';
import { ComboChart, ScaleTypes } from '@carbon/charts-react'
import '@carbon/charts';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

interface OutputData {
    group: string;
    date: string;
    value?: number;
    sr?: number;
}

function convert(metrics: Metric[]): OutputData[] {
    return metrics.flatMap(item => {
        const date = new Date(item.time).toISOString();
        return [
            { group: 'Success', date, value: item.success },
            { group: 'Fail', date, value: item.fail },
            { group: 'Success Ratio', date, sr: item.success_ratio * 100 },
        ];
    });
}

const options = {
    title: 'Metrics Over Time',
    data: {
        loading: true,
    },
    axes: {
        left: {
            title: 'Number of pipelineruns',
            stacked: true,
            mapsTo: 'value',
            titleOrientation: 'right'
        },
        bottom: {
            scaleType: ScaleTypes.TIME,
            mapsTo: 'date'
        },
        right: {
            title: 'Success Ratio (%)',
            scaleType: 'linear',
            mapsTo: 'sr',
            correspondingDatasets: [
                'Success Ratio'
            ],
            titleOrientation: 'left'
        }
    },
    curve: 'curveMonotoneX',
    comboChartTypes: [
        {
            type: 'area',
            options: {
                points: {
                    enabled: true
                }
            },
            correspondingDatasets: [
                'Success',
                'Fail',
            ],
        },
        {
            type: 'line',
            correspondingDatasets: [
                'Success Ratio'
            ],
            options: {
                points: {
                    enabled: true
                }
            },
        }
    ],
    color: {
        scale: {
            'Success': 'green',
            'Fail': 'red',
            'Success Ratio': 'blue'
        }
    },
    height: '400px'
};

const MetricsOverTime = ({ metrics }: { metrics: Metric[] }) => {
    const modifiedOptions = {
        ...options,
        data: { loading: false },
    };

    const data = convert(metrics);
    return (
        <Card>
            <CardContent>
                <ComboChart
                    data={data}
                    /* @ts-ignore */
                    options={modifiedOptions}
                 />
            </CardContent>
        </Card>
    );
};

const ErrorChart = ({ title }: { title: string }) => {
    const modifiedOptions = {
        ...options,
        data: { loading: false },
        title: title,
    };
    return (
        <Card>
            <CardContent>
                <ComboChart
                    data={[]}
                    /* @ts-ignore */
                    options={modifiedOptions}
                 />
            </CardContent>
        </Card>
    );
};

export const EntityRollingMetrics = () => {
    const { entity } = useEntity();
    const entityRef = stringifyEntityRef(entity);

    const { loading, error, metrics } = useEntityRollingMetrics(entityRef);

    if (loading) {
        return (
            <Card>
                <CardContent>
                    <ComboChart
                        data={[]}
                        /* @ts-ignore */
                        options={options} />
                </CardContent>
            </Card>
        );
    } else if (error) {
        return <ErrorChart title={error.message} />;
    }

    return metrics && metrics.length > 0 ? <MetricsOverTime metrics={metrics} /> : <ErrorChart title="No data available" />;
}