import {
  createBaseThemeOptions,
  pageTheme as defaultPageThemes,
  PageTheme,
  palettes,
  createUnifiedTheme,
  defaultTypography,
} from '@backstage/theme';

import { alpha } from '@material-ui/core/styles';

const pageThemesFontColorOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  pageThemesFontColorOverride[key] = {
    ...defaultPageThemes[key],
    fontColor: '#172B4D',
  };
});

export const ibmLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#0f62fe',
      },
      secondary: {
        main: '#393939',
      },
      text: {
        primary: '#161616',
        secondary: '#161616',
      },
      error: {
        main: '#da1e28',
      },
      warning: {
        main: '#f1c21b',
      },
      info: {
        main: '#0043ce',
      },
      success: {
        main: '#24a148',
      },
      navigation: {
        ...palettes.light.navigation,
        indicator: '#4589ff',
        background: '#161616',
        color: '#ffffff',
        navItem: {
          ...palettes.light.navigation.navItem,
          hoverBackground: '#4c4c4c',
        },
      },
      background: {
        default: '#f4f4f4',
      },
      divider: '#c6c6c6',
    },
  }),
  typography: {
    ...defaultTypography,
    fontFamily: 'IBM Plex Sans, Helvetica Neue, Arial, sans-serif',
  },
  pageTheme: pageThemesFontColorOverride,
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: () => ({
          backgroundImage: 'unset',
          boxShadow: 'unset',
          backgroundColor: "#ffffff",
          borderBottom: "1px solid #e0e0e0",
        }),
        title: () => ({
          color: '#161616',
          fontWeight: 600,
        }),
        subtitle: () => ({
          color: '#161616',
        }),
        type: () => ({
          color: '#161616',
        }),
      },
    },
    BackstageOpenedDropdown: {
      styleOverrides: {
        icon: {
          '& path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    BackstageTable: {
      styleOverrides: {
        root: {
          '&> :first-child': {
            borderBottom: '1px solid #c6c6c6',
            boxShadow: 'none',
            background: '#ffffff',
          },
          '& th': {
            borderTop: 'none',
            textTransform: 'none !important',
            background: '#e0e0e0',
            borderBottom: '#c6c6c6',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&[aria-expanded=true]': {
            backgroundColor: '#f4f4f4',
            color: '#161616',
          },
          '&[aria-expanded=true] path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(22,22,22,0.5)',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontWeight: 400,
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
        },
        colorSecondary: '#0353e9',
      },
    },
    MuiChip: {
      styleOverrides: {
        deleteIcon: {
          color: '#393939',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&[aria-expanded]': {
            backgroundColor: '#f4f4f4',
            color: '#161616',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 10,
        },
        switchBase: {
          padding: 12,
        },
        thumb: {
          // backgroundColor: '#FFFFFF',
          height: 14,
          width: 14,
        },
        track: {
          borderRadius: 9,
        },
      },
    },
    BackstageItemCardHeader: {
      styleOverrides: {
        root: {
          color: '#ffffff !important',
          backgroundImage: 'unset !important',
          backgroundColor: '#0f62fe !important'
        }
      }
    }
  },
});
