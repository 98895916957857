import React, {useState, useEffect} from 'react';
import FormControl from '@mui/material/FormControl';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { useDebounce } from 'use-debounce';
import type { FieldValidation } from '@rjsf/utils';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const EntityNameDefaultValidator = ({rawErrors,
  required,
  formData,
  idSchema,
  onChange,
}: FieldExtensionComponentProps<string>) => {
    const catalogApi = useApi(catalogApiRef);
    const [text, setText] = useState('');
    const [debouncedText] = useDebounce(text, 1000);
    const [isValid, setIsValid] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [description, setDescription] = useState('Enter a component name');
  
    const MAX_NAME_LENGTH = 253;
    
    useEffect(() => {
      if (debouncedText) {
        setIsFetching(true);

        // Add length validation
        if (debouncedText.length > MAX_NAME_LENGTH) {
          setIsFetching(false);
          setIsValid(false);
          setDescription(`❌ Name must be ${MAX_NAME_LENGTH} characters or less`);
          return;
        }

        // Add regex validation
        const namePattern = /^[a-z0-9]*[a-z0-9\-\.]*[a-z0-9]*$/;
        if (!namePattern.test(debouncedText)) {
          setIsFetching(false);
          setIsValid(false);
          setDescription('❌ Name must contain only lowercase letters, numbers, hyphens and dots');
          return
        }

        catalogApi.getEntityByRef(`component:default/${debouncedText}`)
          .then((value) => {
            console.log('value');
            if (!value) {
              // Good to go
              setDescription('✅ Name is unique and valid');
              setIsFetching(false);
              setIsValid(true);
            } else {
              // Not valid
              setDescription('❌ Name is not unique.');
              setIsFetching(false);
              setIsValid(false);
            }
          }).catch(error => {
            setDescription('Server error: Cannot get entities. Try again later.')
            setIsFetching(false);
            setIsValid(false);
            console.error(error);
          });
      }
    }, [debouncedText]);

  return (
    <FormControl
      required={required}
      error={required ? (rawErrors?.length > 0 && !formData) : (rawErrors?.length > 0)}
    >
      {isFetching ? <LinearProgress/> : null}
      <TextField
        id={idSchema?.$id}
        label='Component name'
        placeholder='component-name'
        helperText={description}
        required={required}
        value={formData ?? ''}
        onChange={e => {
          setText(e.target?.value || '');
          onChange(e.target?.value);
        }}
        margin="dense"
        error={required ? !isValid : text.length > 0 && !isValid}
      />
    </FormControl>
  )
}

export const validateEntityNameDefaultNamespace = (
  value: string,
  validation: FieldValidation,
) => {
  const isValid = /^[a-z0-9]*[a-z0-9\-\.]*[a-z0-9]*$/g.test(value);
  if (!isValid) {
    validation.addError("String must satisfy ^[a-z0-9]*[a-z0-9\-\.]*[a-z0-9]*$");
  }
}