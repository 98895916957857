import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useEntityStaticMetric } from '../../hooks/useEntityStaticMetric';
import { Metric } from '@itz/backstage-plugin-telemetry-backend';
import { MeterChart } from '@carbon/charts-react'
import '@carbon/charts/styles.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

interface OutputData {
    group: 'success' | 'failures';
    value: number;
}

function convert(metric: Metric): OutputData[] {
    return [
        { group: 'success', value: metric.success },
        { group: 'failures', value: metric.fail }
    ];
}

const options = {
    title: 'Success Ratio',
    height: '130px',
    data: { loading: true },
    meter: {
        proportional: {
            total: 0,
            unit: 'pipeline runs',
        }
    },
    color: {
        scale: {
            success: 'green',
            failures: 'red'
        }
    }
};

const SuccessRatioChart = ({ metric }: { metric: Metric }) => {
    const modifiedOptions = { 
        ...options, 
        data: { loading: false }, 
        meter: { proportional: { total: metric.total }}
    };
    return (
        <Card>
        <CardContent>
            <MeterChart
                data={convert(metric)}
                options={modifiedOptions}
             />
        </CardContent>
    </Card>
    );
};

const ErrorChart = ({title}: {title: string}) => {
    const modifiedOptions = { 
        ...options, 
        data: { loading: false },
        title: title,
    };
    return (
        <Card>
        <CardContent>
            <MeterChart
                data={[]}
                options={modifiedOptions}
             />
        </CardContent>
    </Card>
    );
};

export const EntityStaticMetric = () => {
    const { entity } = useEntity();
    const entityRef = stringifyEntityRef(entity);

    const { loading, error, metrics } = useEntityStaticMetric(entityRef);

    if (loading) {
        return (
            <Card>
                <CardContent>
                    <MeterChart data={[]} options={options} />
                </CardContent>
            </Card>
        );
    } else if (error) {
        return <ErrorChart title={error.message} />;
    }

    return metrics && metrics.length === 1 ? <SuccessRatioChart metric={metrics[0]} /> : <ErrorChart title="No data available"/>;
}