import { discoveryApiRef, fetchApiRef, useApi } from "@backstage/core-plugin-api";
import { Metric } from "@itz/backstage-plugin-telemetry-backend";
import useAsync from 'react-use/esm/useAsync';

export function useEntityRollingMetrics(entityRef: string): {
    error?: Error,
    loading: boolean,
    metrics: Metric[],
} {
    const discoveryApi = useApi(discoveryApiRef);
    const { fetch } = useApi(fetchApiRef);

    const { value, loading, error } = useAsync(async (): Promise<Metric[]> => {
        const response = await fetch(
            `${await discoveryApi.getBaseUrl('telemetry-backend')}/metrics/rolling?entityRef=${entityRef}`,
        );

        if (!response.ok) {
            throw new Error('cannot get entity metrics');
        }
        return response.json();
    }, []);

    return { loading, error, metrics: value ?? [] };
}