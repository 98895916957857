import React, {useState, useEffect} from 'react';
import FormControl from '@mui/material/FormControl';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { useDebounce } from 'use-debounce';
import type { FieldValidation } from '@rjsf/utils';

function isValidUrl(url: string): boolean {
  try {
    const _ = new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

export const LinkValidator = ({
  onChange,
    required,
    schema: { title = 'URL' },
    rawErrors,
    formData,
    uiSchema: { 'ui:autofocus': autoFocus },
    idSchema,
    placeholder,
}: FieldExtensionComponentProps<string>) => {

  const [text, setText] = useState('');
  const [debouncedText] = useDebounce(text, 1000);
  const [isValid, setIsValid] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [description, setDescription] = useState('A valid URL');

  useEffect(() => {
    if (debouncedText) {
      setIsFetching(true);
      if (!isValidUrl(debouncedText)) {
        setIsFetching(false);
        setIsValid(false);
        setDescription(`❌ URL is not valid`);
        return;
      }
      fetch(debouncedText, { method: 'HEAD' }).then((response) => {
        if (!response.ok) {
          setIsFetching(false);
          setIsValid(false);
          setDescription(`❌ URL is not valid. HTTP ${response.status} ${response.statusText}`);
        } else {
          setIsFetching(false);
          setIsValid(true);
          setDescription('✅ URL is valid');
        }        
      }).catch((error) => {
        setIsFetching(false);
        setIsValid(false);
        setDescription(`❌ URL is not valid. ${error}`)
      })
    }
  }, [debouncedText]);

  return (
    <FormControl
      margin="normal"
      required={required}
      error={required ? (rawErrors?.length > 0 && !formData) : (rawErrors?.length > 0)}
    >
      {isFetching ? <LinearProgress/> : null}
      <TextField
        id={idSchema?.$id}
        label={title}
        placeholder={placeholder}
        helperText={description}
        required={required}
        value={formData ?? ''}
        onChange={e => {
          setText(e.target?.value || '');
          onChange(e.target?.value);
        }}
        margin="normal"
        error={required ? !isValid : text.length > 0 && !isValid}
        inputProps={{ autoFocus }}
      />
    </FormControl>
  );
};

export const validateLink = (
  value: string,
  validation: FieldValidation,
) => {
  if (!isValidUrl(value)) {
    validation.addError("Invalid URL. Make sure it's a direct link");
  }
}