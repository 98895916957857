import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
  CatalogTable,
  CatalogTableColumnsFunc,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  techdocsPlugin,
} from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis, bluepagesOIDCAuthApiRef } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { discoveryApiRef, IdentityApi, useApi } from '@backstage/core-plugin-api';
// import { setTokenCookie } from './cookieAuth';
import { ValidateLinkExtension, SignedInUserExtension, EntityNameDefaultValidatorExtension } from '@itz/backstage-plugin-scaffolder-itz-deployer-extensions-react';
import { UnifiedThemeProvider } from '@backstage/theme';
import { ibmLightTheme } from './theme/ibm-light';
import { LightFilledIcon } from './theme/ibm-icons';

const customColumnsFunc: CatalogTableColumnsFunc = () => {
  return [
    CatalogTable.columns.createNameColumn(),
    CatalogTable.columns.createOwnerColumn(),
    CatalogTable.columns.createSpecTypeColumn(),
    CatalogTable.columns.createSpecLifecycleColumn(),
    CatalogTable.columns.createMetadataDescriptionColumn(),
    CatalogTable.columns.createTagsColumn(),
  ];
};
const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: props => {
      useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          providers= {
            [{
              id: 'bluepages',
              title: 'IBM Verify',
              message: 'Sign in with w3id',
              apiRef: bluepagesOIDCAuthApiRef,
            }]
          }
          auto
          align="center"
          onSignInSuccess={async (identityApi: IdentityApi) => {
            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  themes: [
    {
      id: 'ibm-light-theme',
      title: 'IBM Light theme',
      variant: 'light',
      icon: <LightFilledIcon />,
      Provider: ({children}) => (
        <UnifiedThemeProvider theme={ibmLightTheme} children={children}/>
      )
    }
  ]
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage columns={customColumnsFunc}/>} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/create" element={<ScaffolderPage />} >
      <ScaffolderFieldExtensions>
        <ValidateLinkExtension/>
        <SignedInUserExtension/>
        <EntityNameDefaultValidatorExtension/>
      </ScaffolderFieldExtensions>
    </Route>
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
