import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import type { FieldValidation } from '@rjsf/utils';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { useUserProfile } from '@backstage/plugin-user-settings';

export const SignedInUser = ({
  rawErrors,
  required,
  formData,
  idSchema,
  onChange,
}: FieldExtensionComponentProps<string>) => {
  const { backstageIdentity } = useUserProfile();

  if (backstageIdentity) {
    // Backstage requires this onChange to pass values back to the scaffolder
    onChange(backstageIdentity.userEntityRef)
    return (
      <TextField
        id={idSchema?.$id}
        label="Owner"
        defaultValue={backstageIdentity.userEntityRef}
        value={formData ?? backstageIdentity.userEntityRef}
        error={rawErrors?.length > 0 && !formData}
        helperText="User entity ref must start with `user:`. Validation pattern: /^user:\S+@\S+\.\S+$/"
        required={required}
        slotProps={{
          input: {
            readOnly: true,
          },
        }}
      />
    );
  } 
    return <></>
  
}

export const validateSignedInUser = (
  value: string,
  validation: FieldValidation,
) => {
  const isUserValid = /^user:\S*\/?\S+@\S+\.\S+$/g.test(value);
  if (!isUserValid) {
    validation.addError("User entity ref must start with `user:`. Validation pattern: /^user:\S+@\S+\.\S+$/");
  }
}