import React, { useCallback } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import {
  catalogApiRef,
  useEntity,
} from '@backstage/plugin-catalog-react';

import EditIcon from '@material-ui/icons/Edit';
import {
  ANNOTATION_EDIT_URL,
  ANNOTATION_LOCATION, ComponentEntity,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import CachedIcon from '@material-ui/icons/Cached';
import {
  alertApiRef,
  errorApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import {Link} from '@backstage/core-components';
import { catalogEntityRefreshPermission } from '@backstage/plugin-catalog-common/alpha';
import { useEntityPermission } from '@backstage/plugin-catalog-react/alpha';
import Grid from "@mui/material/Grid2";

function calculateMetrics(annotations: any) {
  const successfulRuns = +annotations['techzone.ibm.com/metrics-success'] || 0;
  const failedRuns = +annotations['techzone.ibm.com/metrics-fail'] || 0;
  const totalDeployments = successfulRuns + failedRuns;
  let successRate = 0;
  if (totalDeployments > 0) {
    successRate = successfulRuns / totalDeployments * 100;
    successRate = Math.round(successRate * 100) / 100;
  }

  return (
    <Stack spacing={1}>
      <h3>Metrics</h3>
      <p>This pipeline has been deployed a total of {totalDeployments} times.</p>
      <ul>
        <li>Successful runs: {successfulRuns}</li>
        <li>Failed runs: {failedRuns}</li>
      </ul>
      <p>Success rate: {successRate}%. </p>
    </Stack>
  );
}

export default function CustomEntityAboutCard() {
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  const errorApi = useApi(errorApiRef);
  const { entity } = useEntity();

  const { allowed: canRefresh } = useEntityPermission(
    catalogEntityRefreshPermission,
  );

  const entityMetadataEditUrl =
    entity.metadata.annotations?.[ANNOTATION_EDIT_URL];

  const entityLocation = entity.metadata.annotations?.[ANNOTATION_LOCATION];
  const allowRefresh =
    entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:');
  const refreshEntity = useCallback(async () => {
    try {
      await catalogApi.refreshEntity(stringifyEntityRef(entity));
      alertApi.post({
        message: 'Refresh scheduled',
        severity: 'info',
        display: 'transient',
      });
    } catch (e) {
      // @ts-ignore
      errorApi.post(e);
    }
  }, [catalogApi, entity, alertApi, errorApi]);

  return (
    <Card>
      <CardHeader
        style={{
          marginBottom: '1rem',
        }}
        title="About"
        action={
          <>
            {allowRefresh && canRefresh && (
              <IconButton
                aria-label="Refresh"
                title="aboutCard.refreshButtonTitle"
                onClick={refreshEntity}
              >
                <CachedIcon />
              </IconButton>
            )}
            <IconButton
              component={Link}
              aria-label="Edit"
              disabled={!entityMetadataEditUrl}
              title="aboutCard.editButtonTitle"
              to={entityMetadataEditUrl ?? '#'}
            >
              <EditIcon />
            </IconButton>
          </>
        }
      />
      <Divider />
      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <h3>Name</h3>
            <code>{entity.metadata.name}</code>
          </Stack>
          <Stack spacing={1}>
            <h3>Description</h3>
            <p>{entity.metadata.description}</p>
          </Stack>
          {entity.kind.toLowerCase() === 'component' &&
            (entity as ComponentEntity).spec.type.toLowerCase() ===
              'pipeline' && (
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <h3>Links</h3>
                  <a
                    style={{ textDecoration: 'underline', color: 'blue' }}
                    target="_blank"
                    href={
                      entity.metadata.annotations![
                        'techzone.ibm.com/tekton-pipeline-location'
                      ]
                    }
                  >
                    Pipeline
                  </a>
                  <a
                    style={{ textDecoration: 'underline', color: 'blue' }}
                    target="_blank"
                    href={
                      entity.metadata.annotations![
                        'techzone.ibm.com/tekton-pipeline-run-location'
                      ]
                    }
                  >
                    Pipeline Run
                  </a>
                  {entity.metadata.annotations?.[
                    'techzone.ibm.com/cluster-config'
                  ] && (
                    <a
                      style={{ textDecoration: 'underline', color: 'blue' }}
                      target="_blank"
                      href={
                        entity.metadata.annotations![
                          'techzone.ibm.com/cluster-config'
                        ]
                      }
                    >
                      Cluster configurations
                    </a>
                  )}
                </Stack>
                {/* {entity.metadata.annotations && (
                  <>
                    {calculateMetrics(entity.metadata.annotations!)}
                  </>
                )} */}
              </Stack>
            )}
          <Stack spacing={1}>
            <h3>Tags</h3>
            <Grid>
              {(entity?.metadata?.tags || []).map(tag => (
                <Chip key={tag} size="small" label={tag} />
              ))}
            </Grid>

          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}